import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HashLink as HLink } from 'react-router-hash-link';

class Header extends Component {

   render() {
      let imgattr = 'image'

      return (
         <div>
            <header id="home">
               <nav className="navbar navbar-inverse navbar-expand-lg header-nav fixed-top light-header">
                  <div className="container">
                     <a className="navbar-brand" href={`${process.env.PUBLIC_URL}/`}>
                        <img
                           src={process.env.PUBLIC_URL + 'assets/img/logo2.png'}
                           alt={imgattr}
                           style={{ width: '120px', height: 'auto' }} // Ajusta el tamaño según tus necesidades
                        />
                     </a>
                     <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCodeply">
                        <i className="icofont-navigation-menu"></i>
                     </button>
                     <div className="collapse navbar-collapse" id="navbarCodeply">
                        <ul className="nav navbar-nav ml-auto">
                           <li><HLink className="nav-link" smooth to={`/#home`}>Inicio</HLink> </li>
                           <li><HLink className="nav-link" smooth to={`/#feature`}>Características</HLink></li>
                           <li><HLink className="nav-link" smooth to={`/#feature1`}>Sobre nosotros</HLink></li>
                           <li><HLink className="nav-link" smooth to={`/#screenshots`}>Galería</HLink></li>
                           <li><HLink className="nav-link" smooth to={`/#contact`}>Contacto</HLink></li>
                        </ul>
                     </div>
                  </div>
               </nav>
            </header>
         </div>

      );
   }
}

export default Header;
